import { useNavigation } from "@remix-run/react";

type MingleInputProps = {
  name?: string;
  label?: string;
  placeHolder?: string;
  error?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  labelIcon?: React.ReactNode;
  value?: string | number;
  required?: boolean;
  defaultValue?: string | number;
  disabled?: boolean;
  classNames?: string;
  classNamesInput?: string;
  autoFocus?: boolean;
  autoComplete?: boolean;
  isLarge?: boolean;
  list?: string;
  min?: number;
  max?: number;
  maxLength?: number;
};

export const MingleInput = ({
  name,
  label,
  labelIcon,
  placeHolder,
  required,
  min,
  max,
  maxLength,
  error,
  value,
  defaultValue,
  type = "text",
  disabled = false,
  onChange = () => {},
  onFocus = () => {},
  startIcon,
  endIcon,
  autoFocus = false,
  isLarge = false,
  classNames,
  classNamesInput,
  list,
}: MingleInputProps) => {
  let navigation = useNavigation();

  return (
    <div className={`relative w-full max-w-md ${classNames ? classNames : ""}`}>
      <label
        htmlFor={name}
        className="flex gap-2 text-xs font-medium mb-1 text-gray-800 dark:text-gray-200 "
      >
        {labelIcon}
        {label}
      </label>
      <div className={`relative`}>
        <input
          autoFocus={autoFocus}
          {...{
            id: name,
            name,
            required,
            min,
            max,
            maxLength,
            list,
            ...(value || value === 0 ? { value } : {}),
            ...(defaultValue || defaultValue === 0 ? { defaultValue } : {}),
            ...(defaultValue || defaultValue === 0
              ? { key: defaultValue }
              : {}),
            ...(disabled
              ? { disabled }
              : { disabled: navigation.state !== "idle" }),
            type,
            // disabled,
            "aria-invalid": error ? "true" : "false",
            placeholder: placeHolder,
            onChange: (e) => {
              onChange(e);
            },
            onFocus: (e) => {
              onFocus(e);
            },
          }}
          className={`input input-bordered w-full text-left input-secondary text-gray-500 dark:text-gray-200 border-gray-300 dark:border-gray-900 focus:border-green-500 focus:outline-offset-0 focus:outline-green-200 focus:outline-4 [color-scheme:light] dark:[color-scheme:dark] disabled:text-gray-800 dark:disabled:bg-gray-900  ${
            error ? "border-red-500" : ""
          } ${isLarge ? "lg:font-[700] lg:text-2xl" : ""} ${
            startIcon ? "pl-14" : "pl-6"
          }  ${endIcon ? "pr-14" : ""}   ${
            classNamesInput ? classNamesInput : ""
          }`}
        />
        {startIcon && (
          <div className="absolute w-10 h-8 top-1.5 left-5 pr-3 flex items-center pointer-events-none">
            {startIcon}
          </div>
        )}{" "}
        {endIcon && (
          <div
            className={`absolute w-10 h-8 top-1.5 -right-1 pr-3 flex items-center ${
              type !== "password" ? " pointer-events-none" : ""
            }`}
          >
            {endIcon}
          </div>
        )}
        {error && (
          <label className="label">
            <p className="text-sm font-medium text-red-500 text-center w-full">
              {error}
            </p>
          </label>
        )}
      </div>
    </div>
  );
};
